@import url("https://fonts.googleapis.com/css2?family=Bitter&display=swap");
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "Bitter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.gradientLetters {
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(90deg, #f4a261 0%, #e76f51 100%);
}
.reverseGradient {
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(
    180deg,
    #f4a261 0%,
    #e76f51 100%
  ) !important;
  transition: all ease 1s;
}
#cards {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-width: 916px;
  width: calc(100% - 20px);
}

.card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  box-shadow: 1px 1px 5px white;
  flex-direction: column;
  position: relative;
}

.card:hover::before {
  opacity: 1;
}
.card:hover:after {
  opacity: 1;
}

.card::before,
.card::after {
  border-radius: inherit;
  content: "";
  height: 100%;
  left: 0px;
  opacity: 0;
  position: absolute;
  top: 0px;
  transition: opacity 500ms;
  width: 100%;
}

.card::before {
  background: radial-gradient(
    800px circle at var(--mouse-x) var(--mouse-y) #8bd9ff,
    transparent 40%
  );
  z-index: 1;
}

.card::after {
  background: radial-gradient(
    600px circle at var(--mouse-x) var(--mouse-y),
    #8bd9ff,
    transparent 40%
  );
  z-index: 1;
}
.card > .card-content {
  background-color: rgba(3, 10, 41, 0.9);
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 1;
  inset: 1px;
  padding: 10px;
  position: absolute;
  z-index: 2;
}
